import { CustomerRegistrationV2 } from 'components/customer-registration/CustomerRegistrationV2';
import { isRegistrationAllowedForCountry } from 'constants/AllowedRegistrationCountries';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { Navigate } from 'react-router-dom';
import { RegistrationStartupType } from 'types/models';

import { EmailConfirmationPage } from './email-confirmation/EmailConfirmationPage';

export function RootRoute() {
  const { prospectData, emailRecoveryHint, registrationFlow } =
    useRegistrationContext();

  if (emailRecoveryHint) {
    return <EmailConfirmationPage />;
  }

  if (
    registrationFlow === RegistrationStartupType.ShopSpecific ||
    (prospectData?.address?.country &&
      isRegistrationAllowedForCountry(prospectData.address.country))
  ) {
    return <CustomerRegistrationV2 />;
  }

  return <Navigate to="/404" />;
}

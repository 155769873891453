import { ISOCountryCode, parseCountryCode } from '@pmi.web/countries';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useAvailableCountries } from 'hooks/useAvailableCountries';
import { useProspect } from 'hooks/useProspect';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { type IAvailableCountry } from 'types/ICountries';
import { ProspectData, RegistrationStartupType } from 'types/models';

import { useContinueToken } from './hooks/useContinueToken';
import { useCountry } from './hooks/useCountry';
import { useProspectId } from './hooks/useProspectId';
import { useRedirectUrl } from './hooks/useRedirectUrl';
import { useShopId } from './hooks/useShopId';
import { useSponsorId } from './hooks/useSponsorId';
import {
  IRegistrationContextValues,
  RegistrationContext
} from './RegistrationContext';
import { sortAvailableCountriesByShopId } from './utils/CountriesHelper';
import * as RegistrationHelper from './utils/RegistrationHelper';

interface IRegistrationContextProviderProps {
  readonly children: ReactNode;
}

export function RegistrationContextProvider({
  children
}: IRegistrationContextProviderProps) {
  const urlShopId = useShopId();
  const urlCountry = useCountry();
  const urlSponsorId = useSponsorId();
  const urlProspectId = useProspectId();
  const urlRedirectUrl = useRedirectUrl();
  const urlContinueToken = useContinueToken();
  const {
    i18n: { language }
  } = useTranslation();

  const registrationFlow = useMemo(
    () =>
      RegistrationHelper.getRegistrationFlow({
        urlCountry,
        urlProspectId,
        urlSponsorId,
        urlShopId
      }),
    [urlCountry, urlProspectId, urlShopId, urlSponsorId]
  );

  const {
    data: prospectOrHint,
    isLoading,
    error
  } = useProspect(urlProspectId, urlContinueToken);

  const {
    data: availableCountries,
    isLoading: getAvailableCountriesLoading,
    error: getAvailableCountriesError
  } = useAvailableCountries(registrationFlow);

  const contextValue: IRegistrationContextValues = useMemo(() => {
    let country: ISOCountryCode | undefined;
    let availableCountriesSorted: ReadonlyArray<IAvailableCountry> | undefined;

    if (
      registrationFlow === RegistrationStartupType.ShopSpecific &&
      availableCountries
    ) {
      const countryShop = availableCountries?.find(
        country => country.shopId.toLowerCase() === urlShopId?.toLowerCase()
      );

      if (countryShop) {
        availableCountriesSorted = sortAvailableCountriesByShopId(
          availableCountries,
          countryShop.shopId,
          countryShop.countryCode
        );
        country = availableCountriesSorted[0].countryCode;
      } else {
        availableCountriesSorted = [...availableCountries];
        country = availableCountries[0].countryCode;
      }
    } else if (registrationFlow === RegistrationStartupType.CountrySpecific) {
      country = urlCountry ? parseCountryCode(urlCountry) : undefined;
    }

    let prospectData: ProspectData = {
      id: urlProspectId,
      locale: language,
      sponsorId: urlSponsorId,
      address: country ? { country } : undefined
    };

    let emailRecoveryHint: string | undefined = undefined;

    if (prospectOrHint && 'status' in prospectOrHint) {
      emailRecoveryHint = prospectOrHint.hint;
    } else if (prospectOrHint) {
      prospectData = prospectOrHint;
    }

    return {
      redirectUrl: urlRedirectUrl,
      emailRecoveryHint,
      prospectData,
      resumeToken: urlContinueToken,
      availableCountries: availableCountriesSorted,
      registrationFlow
    };
  }, [
    registrationFlow,
    urlProspectId,
    language,
    urlSponsorId,
    prospectOrHint,
    urlRedirectUrl,
    urlContinueToken,
    availableCountries,
    urlShopId,
    urlCountry
  ]);

  const isReady = useMemo(
    () => !isLoading && !getAvailableCountriesLoading,
    [getAvailableCountriesLoading, isLoading]
  );

  if (registrationFlow === undefined || getAvailableCountriesError) {
    return <Navigate to={'/404'} />;
  }

  if (error && urlProspectId) {
    if (urlRedirectUrl) {
      window.location.assign(urlRedirectUrl);
      return null;
    }

    return <Navigate to={'/404'} />;
  }

  if (!isReady) {
    return (
      <div className="flex flex-row justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <RegistrationContext.Provider value={contextValue}>
      {children}
    </RegistrationContext.Provider>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getAvailableCountries } from 'services/ShopService';
import { RegistrationStartupType } from 'types/models';

const TTL = 5 * 60 * 60 * 1000; // 5 hours in milliseconds;

export function useAvailableCountries(
  registrationFlow?: RegistrationStartupType | undefined
) {
  const query = useQuery({
    queryKey: ['availableCountries'],
    queryFn: () => getAvailableCountries(),
    staleTime: TTL,
    retry: false,
    enabled: registrationFlow === RegistrationStartupType.ShopSpecific
  });

  return { ...query, error: query.error ?? undefined };
}
